import './index.scss'
$("footer dl dd").mouseenter(function () {
    $("footer dl dt").removeClass("active");
    $(this).parent().find("dt").addClass("active");
})
$("footer dl dd").mouseleave(function () {
    $("footer dl dt").removeClass("active");
})
$(".right_nav .top").click(function () {
    $('body,html').animate({
        scrollTop: 0
    }, 400);
})