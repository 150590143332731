import './index.scss'

$(".hamburger").click(function(){
    $(this).toggleClass("active");
    $(".mob-nav").toggleClass("active");
})
$(".nav li").hover(function(){
    $(".header-bg").show()
    $(this).find(".navchild").css("display","flex");
},function(){
    $(".header-bg").hide()
    $(this).find(".navchild").css("display","none");
})
$(".search").click(function(){
    $("header .head").hide();
    $(".search-box").css("display","flex");
})
$(".search-out").click(function(){
    $("header .head").show();
    $(".search-box").hide();
})
$(".nav li:first").hover(function(){
    $(".header-bg").hide()
})
$(".nav li:last").hover(function(){
    $(".header-bg").hide()
})
$(".mob-nav .nLi").click(function() {
    $(this).find(".sub").slideToggle();
    $(this).toggleClass("active");
})

// $("header .navchild a").click(function () {
//     console.log(1);
//     if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
//           var $target = $(this.hash);
//           $target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
//           if ($target.length) {
//               var targetOffset = $target.offset().top;
//               $('html,body').animate({
//                   scrollTop: targetOffset - 98
//               },500);
//               return false;
//           }
//       }
// })
// $('.abouts').on('click', function() {
//     window.location.href = "http://localhost:8080/news.html#news";
// });