module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="humburger-nav">\r\n    <a href="index.html">\r\n        <img src="' +
((__t = ( require('./images/hum-icon.png') )) == null ? '' : __t) +
'" alt="">首页\r\n    </a>\r\n    <span>></span>\r\n    <a href="javascript:;">产品</a>\r\n    <span>></span>\r\n    <a href="javascript:;">适老家居</a>\r\n    <span>></span>\r\n    <a href="javascript:;">综合服务台</a>\r\n    <span>></span>\r\n</div>';

}
return __p
}